

#canvas_holder {
    position: fixed;
    height: 100vh;
    left: 0;
    right: 0;
    overflow: scroll;
    padding-right: 370px;
    padding-bottom: 200px;
    top: 60px;
}

#canvas_holder_sandbox {
    position: absolute;
    left: 0;
    width: 1050px;
    overflow: hidden;
}

#right_of_map {
    position: absolute;
    width: 400px;
    /*left:1040px;*/

    /*overflow: auto;
	margin-bottom: 300px;
	height: auto;*/

    /*margin: 60px 0 0 250px;*/
    /*padding: 0 30px;*/
    overflow-y: scroll;
    position: fixed;
    /*left: 1040px;*/
    right: 0px;
    top: 0;
    height: 100vh;
}

#orders {
    padding-top: 40px;
}

#under_sandbox_map {
    position: absolute;
    top: 380px;
    left: 10px;
    width: 700px;
}

#under_sandbox_map p {
    margin-bottom: 0px;
}

.sandbox_input_area {
    float: left;
    padding-right: 15px;
    padding-bottom: 10px;
}
.sandbox_input_area textarea {
    margin-top: 5px;
    margin-bottom: 5px;
}

#sandbox_resolve {
    width: 365px;
    font-size: 18px;
}

#sandbox_log {
    color: #333;
}

#credits {
    padding-top: 50px;
}
#credits p {
    color: #222;
    font-size: 12px;
}

.player_name {
    font-weight: bold;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
}
.player_name a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    font-weight: bold;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
}

.dead_player {
    text-decoration: line-through;
}

.highlighted_player {
    font-size: 25px;
}

.highlighted_order {
    font-weight: bold;
}

.player_stats {
    color: #555;
}

.good_order {
}

.bad_order {
    color: #900;
    text-decoration: underline;
}

.invalid_order {
    color: #888;
    text-decoration: line-through;
}
.invalid_order_no_slash {
    color: #888;
    display: inline-block; /*text-decoration:none did not work. dunno why*/
}

/*rules page*/

.rules_section {
    overflow: auto;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.rules_text {
    /* float: left;
    width: calc(100% - 500px); */
}
.rules_text p,
.rules_text ul {
    margin-bottom: 0px; /*undoing the p formatting up top*/
    max-width: 600px;
}

.example {
    /* float: right; */
    /*width: 350px;*/
    border-style: solid;
    padding-bottom: 25px;
    margin: 16px 0 0;
    flex: 1;
    max-width: 400px;
}

.exmaple_label {
    margin-bottom: 0px; /*undoing the p formatting up top*/
    margin-top: 0px;
}
.example p {
    padding-left: 10px;
    margin-block-start:0px;
    margin-block-end:0px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.example img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 768px) {
    .rules_section {
        flex-direction: row;
    }

    .example {
        margin: 0 0 0 16px;
    }

    .example img {
        min-width: 300px;
    }
}

.example_red_player {
    color: #ff1111;
}
.example_blue_player {
    color: #1111ff;
    padding-top: 10px;
}
.example_green_player {
    color: #11ff11;
    padding-top: 10px;
}

.sample_orders {
    margin-left: 50px;
    padding-left: 10px;
    margin-right: 100px;
    background: #ccc;
    font-family: "Courier New", Courier, monospace;
}

