* {
    box-sizing: border-box;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
}

/*This is an Andy edit and probably dumb*/
h1 {
	margin-bottom: -5px;
}
h2 {
	margin-bottom: -5px;
}
h3 {
	margin-bottom: -5px;
}